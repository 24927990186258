<template>
    <b-modal :title="$t('keys.price_lists.add_products_to_price_list')" v-model="modalActive" no-close-on-backdrop>
        <template #default>
            <TableWithCheckBox @gridReady="setFocus" ref="tableSearch" :columnDefs="columnDefs" :rowData="products" :selected="selectedProducts" :getAllItem="true" selectValue="item_id" searchValue="id" @selectionChanged="selectionChanged"/>
        </template>


        <template #modal-footer>
            <b-button variant="primary" @click="validate">
                <span>
                    <feather-icon icon="PlusIcon" class="mr-50"/>
                    <span class="align-middle">{{ $t('general.add') }}</span>
                </span>
            </b-button>
        </template>
    </b-modal>
</template>
<script>
    import {BButton, BModal} from 'bootstrap-vue'
    import TableWithCheckBox from '@/views/components/Table/TableWithCheckBox'
    import * as Sentry from '@sentry/vue'

    export default {
        components: {
            BModal,
            BButton,
            TableWithCheckBox
        },
        props: {
            products: {
                type: Array
            },
            priceListProducts: {
                type: Array
            },
            productSubcategories: {
                type: Array
            }
        },
        data() {
            return {
                modalActive: false,
                priceList: null,
                columnDefs: [
                    { headerName: this.$t('table_fields.products'), field: 'name', editable: false, filter: true },
                    { headerName: this.$t('table_fields.subcategory'), field: 'product_category_id', filter: true, floatingFilterComponentParams: () => { return  {type: 'select', values: this.productSubcategories} }, cellEditorFramework: 'selectEditor', cellEditorParams: () => { return {values: this.productSubcategories} }, cellRenderer: (params) => this.getCategoryName(params.value)}
                ],
                selectedProducts: []
            }
        },
        methods: {
            setFocus() {
                const rootElement = this.$refs.tableSearch.$el
                const inputElement = rootElement.querySelector('.ag-input-wrapper input[type="text"]')

                // Focus the input element
                if (inputElement) {
                    inputElement.focus()
                }
            },
            getCategoryName(value) {
                if (value) {
                    const item = this.productSubcategories.find(x => x.id === value)
                    if (item) return item.name
                }
                return '/'
            },
            open(priceList) {
                this.modalActive = true
                this.priceList = priceList
                this.selectedProducts = JSON.parse(JSON.stringify(this.priceListProducts))
                /*this.selectedProducts = []
                for (const item of products) {
                    this.selectedProducts.push(item.id)
                }*/
            },
            async validate() {
                try {
                    const payload = []
                    for (const item of this.selectedProducts) {
                        payload.push({item_id: item.id, price: item.sale_price, price_list_id: this.priceList.id})
                    }

                    const response = await this.$http.post(`/api/client/v1/price_lists/items/${this.priceList.id}`, payload)
                    this.modalActive = false
                    this.$emit('itemAdded', response.data.id)
                    this.$printSuccess(this.$t('print.success.add'))
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_edit'))
                }
            },
            selectionChanged(val) {
                this.selectedProducts = val
            }
        }
    }
</script>
<style scoped lang="scss">
.day {
  width: 2rem;
  height: 2rem;
  border: 1px solid black;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1.5rem;
  color: black;
}
.day.active {
  border-color: var(--primary);
  background-color: var(--primary);
  color: white;
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>